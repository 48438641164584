/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body{
	background-color: rgba(254, 253, 252, 1);
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p,
a, button.a abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video  {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	line-height: 1.35;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

a, button.a {
	text-decoration: none;
}

button.a {
	cursor: pointer;
}

button.a:active,
button.a:focus {
	outline: none;
}

body {
	line-height: 1.35;
	font-family: "Graphik Web", Helvetica, sans-serif;
}

button {
	font-family: "Nan Holo", Helvetica, sans-serif;
}

input, select, textarea {
	font-family: "Graphik Web", Helvetica, sans-serif;
}

::placeholder {
	font-family: "Graphik Web", Helvetica, sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #BDBFC9;
}

ol, ul {
	list-style: none;
}

blockquote,q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body, #root {
	position: absolute;
	height: 100vh;
	width: 100vw;
	/* min-width: 1264px; */
}

a, button.a, h1, h2, h3, h4, h5, h6,
p, span, ol, ul, li {
	font-family: "Graphik Web", Helvetica, sans-serif;
	color: rgba(14, 15, 59, 1);
	line-height: 1.35;
}

::-moz-selection {
	background: rgba(51, 163, 245, 0.2);
}

::-webkit-selection {
	background: rgba(51, 163, 245, 0.2);
}

::-ms-selection {
	background: rgba(51, 163, 245, 0.2);
}

::selection {
	background: rgba(51, 163, 245, 0.2);
}

/* .message-bubble-internal span::selection {
	color: white;
	background: rgba(14, 15, 59, 1);
}

.message-bubble-external span::selection {
	color: rgba(14, 15, 59, 1);
	background: white;
} */

pre {
	margin: 20px;
	padding: 20px;
	color: #aaa;
	background-color: rgba(14, 15, 59, 1);
	white-space: pre;
	text-shadow: 0 1px 0 #000;
	border-radius: 4px;
	border-bottom: 1px solid #555;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4) inset, 0 0 20px rgba(0, 0, 0, 0.2) inset;
	font: 16px/24px "League Mono", "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
	overflow: scroll;
}

.intercom-container img {
	max-width: 250px;
}
